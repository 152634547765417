<template>
  <page-title-component title="Logs" />

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table-component
              :columns="columns"
              :rows="logs"
              :sortable="sortable"
              @sorting="sorting"
              :pagination="pagination"
              @search="search"
              @pageChanged="pageChanged">

            <template v-slot:actions>
              <div class="row">
                <div class="col-4">
                  <select class="form-control form-control-sm"
                          v-model="queryParams.user_id"
                          @change="loadData">
                    <option value="">All User</option>
                    <option :value="user.id"
                            v-for="user in users"
                            :key="'user_'+user.id">{{ user.name }} ({{ user.email}})</option>
                  </select>
                </div>
                <div class="col-4">
                  <input type="text"
                         v-model="queryParams.response_status"
                         @keyup="loadData"
                         placeholder="Status Code"
                         class="form-control form-control-sm">
                </div>
              </div>
            </template>

            <template v-slot:response_status="{ row }">
              <span class="badge bg-success" v-if="row.response_status.charAt(0) == 2">{{ row.response_status }}</span>
              <span class="badge bg-warning" v-else-if="row.response_status.charAt(0) == 4">{{ row.response_status }}</span>
              <span class="badge bg-danger" v-else-if="row.response_status.charAt(0) == 5">{{ row.response_status }}</span>
              <span class="badge bg-blue" v-else>{{ row.response_status }}</span>
            </template>

            <template v-slot:action="{ row }">
              <button class="btn btn-sm btn-info me-1" @click="$refs.detailsModal.show(row)">
                <i class="fas fa-eye"></i>
              </button>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <details-modal ref="detailsModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import PageTitleComponent from "@/components/PageTitleComponent";
import DetailsModal from "./DetailsModal.vue";
export default {
  components: {TableComponent, PageTitleComponent, DetailsModal},
  data() {
    return {
      users: [],
      logs: [],
      queryParams: {
        user_id: '',
        response_status: '',
        token: this.$route.query.token,
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Created At",
          field: 'created_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "Name",
          field: 'user.name',
          sortable: true,
          searchable: true,
        },
        {
          label: "Email",
          field: 'user.email',
          sortable: true,
          searchable: true,
        },
        {
          label: "Method",
          field: 'method',
          sortable: true,
          searchable: false,
        },
        {
          label: "Status Code",
          field: 'response_status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.users = [];

      this.axios.get('/users?sort=name&sort_order=asc')
          .then(res => {
            this.users = res.data.data;
          })
    },
    loadData() {
      this.logs = [];

      this.axios.get('/logs', {params: this.queryParams})
          .then((response) => {
            this.logs = response.data.data;
            this.pagination = response.data.meta;
          })
    }
  }
}
</script>