<template>
  <page-title-component title="Notifications" />

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-striped table-bordered">
            <thead>
            <tr>
              <th>Notification</th>
              <th>Created At</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(notification, i) in notifications"
                :key="'notification_'+i">
              <td>{{ generateText(notification) }}</td>
              <td>{{ notification.created_at }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      notifications: []
    }
  },
  created() {
    this.axios.get('/notifications')
      .then(res => {
        this.notifications = res.data.data;
      })
  },
  methods: {
    generateText(notification) {
      if (notification.event === 'new_user') {
        return "New account created by " + notification.email;
      } else if (notification.event === 'new_subscription') {
        return notification.subscribed_by.email + " subscribed " + notification.plan.name;
      } else if (notification.event === 'license_activation') {
        return notification.activated_by.email + " activated " + notification.agency + " license";
      } else if (notification.event === 'subscription_cancel') {
        return notification.user.email + " cancel subscription";
      } else {
        return ''
      }
    }
  }
}
</script>