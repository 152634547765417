<template>
  <page-title-component title="Dashboard"></page-title-component>

  <div class="row layout-spacing" v-if="dashboard">
    <div class="col-xl-3 mb-xl-0 col-lg-6 mb-4 col-md-6 col-sm-6">
      <router-link :to="{name: 'users'}">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-sales-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-user-6"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">User</p>
                <p class="widget-numeric-value">{{ dashboard.users }}</p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xl-3 mb-xl-0 col-lg-6 mb-4 col-md-6 col-sm-6">
      <router-link :to="{name: 'agency'}">
        <div class="widget-content-area  data-widgets br-4">
          <div class="widget  t-customer-widget">
            <div class="media">
              <div class="icon ml-2">
                <i class="flaticon-user-1"></i>
              </div>
              <div class="media-body text-right">
                <p class="widget-text mb-0">Agency</p>
                <p class="widget-numeric-value">{{ dashboard.agencies }}</p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
export default {
  components: {PageTitleComponent},
  data() {
    return {
      dashboard: null
    }
  },
  created() {
    this.axios.get('/dashboard')
        .then(res => {
          this.dashboard = res.data.data;
        })
  }
}
</script>